import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_tU0SxKrPeVRXWcGu2sOnIfhNDbYiKNfDCvYZhRueG0Q from "/vercel/path0/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/vercel/path0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8 from "/vercel/path0/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import siteConfig_ynjhMKs6nptodTgSpGkewjfb62gKlZk0qJ8t3ht7mNg from "/vercel/path0/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_AiTmD7KW_bLoiTACOVH3tizNMjleAZGlVOfjUS6HLk4 from "/vercel/path0/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import plugin_xdQC2I5FZA9BN_8d8MWCXWAuqLDNRFNSkZczMVXWDIs from "/vercel/path0/node_modules/nuxt-csurf/dist/runtime/plugin.js";
import plugin_MeUvTuoKUi51yb_kBguab6hdcExVXeTtZtTg9TZZBB8 from "/vercel/path0/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import consent_client_Fbu9996rYWs_uNG_xjOvhDpn54IkYmpe82nmYiqR6Hk from "/vercel/path0/app/plugins/consent.client.ts";
import matomo_client_BdTsvle0BpB_gdI8Wg14_qwwYcMw75038v2vv2QWi78 from "/vercel/path0/app/plugins/matomo.client.ts";
import rough_notation_client_I_Je89dWqmGXMb9745AinUkqvrbfpwxBir9AYohdV98 from "/vercel/path0/app/plugins/rough-notation.client.ts";
import sentry_RD4MOIP8XUUoVMys2P3VI5hKDs_DdUZCwJoPeVQqIuI from "/vercel/path0/app/plugins/sentry.ts";
export default [
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  _0_siteConfig_tU0SxKrPeVRXWcGu2sOnIfhNDbYiKNfDCvYZhRueG0Q,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  siteConfig_ynjhMKs6nptodTgSpGkewjfb62gKlZk0qJ8t3ht7mNg,
  inferSeoMetaPlugin_AiTmD7KW_bLoiTACOVH3tizNMjleAZGlVOfjUS6HLk4,
  plugin_xdQC2I5FZA9BN_8d8MWCXWAuqLDNRFNSkZczMVXWDIs,
  plugin_MeUvTuoKUi51yb_kBguab6hdcExVXeTtZtTg9TZZBB8,
  consent_client_Fbu9996rYWs_uNG_xjOvhDpn54IkYmpe82nmYiqR6Hk,
  matomo_client_BdTsvle0BpB_gdI8Wg14_qwwYcMw75038v2vv2QWi78,
  rough_notation_client_I_Je89dWqmGXMb9745AinUkqvrbfpwxBir9AYohdV98,
  sentry_RD4MOIP8XUUoVMys2P3VI5hKDs_DdUZCwJoPeVQqIuI
]