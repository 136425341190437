
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as agblEXOsXyVju6tzwB3CVOVq8xtF1b1cHYIrVKuCcdDsIUMeta } from "/vercel/path0/app/pages/agb.vue?macro=true";
import { default as indexXNEFQ_45BjAwM6CKVywW7WXWaOQOf9WzaBic0wLTXHAssMeta } from "/vercel/path0/app/pages/index.vue?macro=true";
import { default as kontaktUc7DngevSTS8pYBS9lp4HN7hD4tI4Poh_yw4IEPnb7AMeta } from "/vercel/path0/app/pages/kontakt.vue?macro=true";
import { default as ImpressumqwBnc73XEuNKfniVD07QiQ_ptAILyKMUj3N8Gbisxo0Meta } from "/vercel/path0/app/pages/Impressum.vue?macro=true";
import { default as DatenschutzRURnMjNOrZNPviID68Qwlp1txX6uKrAL6WTmTz5uobwMeta } from "/vercel/path0/app/pages/Datenschutz.vue?macro=true";
export default [
  {
    name: "agb",
    path: "/agb",
    component: () => import("/vercel/path0/app/pages/agb.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/app/pages/index.vue")
  },
  {
    name: "kontakt",
    path: "/kontakt",
    component: () => import("/vercel/path0/app/pages/kontakt.vue")
  },
  {
    name: "Impressum",
    path: "/Impressum",
    component: () => import("/vercel/path0/app/pages/Impressum.vue")
  },
  {
    name: "Datenschutz",
    path: "/Datenschutz",
    component: () => import("/vercel/path0/app/pages/Datenschutz.vue")
  }
]