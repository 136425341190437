import {
  init,
  addIntegration,
  browserTracingIntegration,
  browserProfilingIntegration,
  moduleMetadataIntegration
} from '@sentry/vue'

async function lazyLoadSentryIntegrations() {
  // don't load on server
  if (!import.meta.client) return

  const {
    replayIntegration,
    replayCanvasIntegration,
    captureConsoleIntegration,
    extraErrorDataIntegration,
    httpClientIntegration,
    reportingObserverIntegration,
    contextLinesIntegration
  } = await import('@sentry/vue')
  addIntegration(
    replayIntegration({
      maskAllText: false,
      blockAllMedia: false
    })
  )

  addIntegration(replayCanvasIntegration())
  addIntegration(captureConsoleIntegration({ levels: ['warn', 'error'] }))
  addIntegration(extraErrorDataIntegration())
  addIntegration(httpClientIntegration())
  addIntegration(reportingObserverIntegration())
  addIntegration(contextLinesIntegration())
}

function getSentryIntegrations() {
  // don't load on server
  if (!import.meta.client) return []

  const router = useRouter()

  const browserTracing = browserTracingIntegration({ router })
  const browserProfiling = browserProfilingIntegration()
  const moduleMetadata = moduleMetadataIntegration()

  return [browserTracing, browserProfiling, moduleMetadata]
}

export default defineNuxtPlugin({
  name: 'sentry',
  parallel: true,
  async setup(nuxtApp) {
    const vueApp = nuxtApp.vueApp

    const {
      public: { sentry }
    } = useRuntimeConfig()

    init({
      app: vueApp,
      dsn: sentry.dsn ?? null,
      environment: sentry.environment,
      integrations: getSentryIntegrations(),
      tracesSampleRate: 0.25,
      profilesSampleRate: 0.5,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1
    })

    lazyLoadSentryIntegrations()
  }
})
